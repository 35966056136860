@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-open {
  overflow: hidden;
}

/* Future - move from tailwind to sass, with react scoped styling and use of sass variables and mixins */

/* Globals / Generic */
.container-border {
  @apply drop-shadow-md rounded-lg;
}

.color-warning {
  color: #c93545;
}

.color-primary {
  color: #0f3052;
}

button:hover {
  filter: brightness(150%);
}

.main-wrapper {
  background: #f1f0ea;
}

.main-wrapper ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.main-wrapper ::-webkit-scrollbar-thumb {
  background: #0f3052;
  border-radius: 0.5rem;
}

.custom-icon {
  fill: #0f3052;
}

/* Tables */
td {
  white-space: nowrap;
}

tr:nth-child(even) {
  background-color: #a2c7ee;
}

table>thead>tr {
  color: #0f3052;
}

td,
th {
  padding-left: 10px;
  padding-right: 10px;
}

tr:hover {
  background-color: #0f3052;
  color: white;
}

table>thead>tr:hover {
  background-color: #fff;
  color: #0f3052;
}

.table-primary {
  color: #0f3052;
}

/* Lists */
ul.custom-list li:nth-child(even) {
  background-color: #a2c7ee;
}

ul.custom-list li:hover {
  background-color: #0f3052;
  color: #fff;
  cursor: pointer;
}

/* React grid */
.grid-component__content h1 {
  color: #0f3052;
}

.grid-component__content * {
  border-color: #a7a7a7;
}

.draggableHandle,
.draggableHandle h1,
.react-resizable-handle {
  z-index: 20;
}

.grid-component__container {
  background: #fefdfb;
  box-shadow: 0px 3px 12px #0000001a;
}

.add-target:hover {
  background: rgba(0, 0, 0, 0.5);
}

.add-target svg {
  display: none;
}

.add-target:hover svg {
  display: block;
}

/* Charts */
.recharts-layer line {
  display: none;
}

.recharts-pie {
  width: 100%;
  height: auto;
}

.custom-pie .recharts-default-legend {
  text-align: center !important;
}

.custom-pie svg {
  overflow: overlay;
  margin-top: 10%;
}

.recharts-legend-wrapper ul a .save-as-csv,
div a .save-as-csv {
  min-width: 1.7rem;
  height: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f3052;
}

div div .csv-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.recharts-legend-wrapper ul .save-as-png,
div div .save-as-png {
  min-width: 1.7rem;
  height: 1.7rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f3052;
  color: #fff;
}

.recharts-legend-wrapper ul .zoom-out {
  min-width: 1.7rem;
  min-height: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

/* .recharts-cartesian-axis-ticks { display: none;} */

/* Widgets */
.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.treeIcon {
  filter: invert(84%) sepia(15%) saturate(1134%) hue-rotate(54deg) brightness(85%) contrast(92%);
}

.health.health-healthy {
  background: #88c971;
}

.health.health-unhealthy {
  background: #f68c1e;
}

.health.health-down {
  background: #c93545;
}

.health {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

/* Form elements */
input[type='text'],
input[type='date'],
input[type='tel'],
input[type='email'] {
  border-color: #707070;
}

.search-filter-form {
  min-width: 200px;
}

.batteryIcon {
  background-color: #88c971;
}

.iconFilling {
  background-color: #2d307c;
}

/* Modals */
.modal-container-alt-full {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.modal-container {
  background: rgba(0, 0, 0, 0.5);
}

.modal-container p,
.modal-container label {
  color: #0f3052;
}

.modal-container h1,
.modal-container h2,
.modal-container h3 {
  color: #0f3052;
}

.modal-container .static h3 {
  color: #f68c1e;
}

.modal-container-alt-full .container {
  height: 95vh;
  overflow-y: auto;
}

.modal-container-alt-full .container.overflow-y-hidden {
  overflow-y: hidden;
}

.table-button,
.filter button,
.modal-container button,
.login-container button,
.profile-container button {
  background-color: #0f3052;
  color: white;
  min-width: 180px;
}

.input-filter {
  padding: 0.4rem;
  padding-left: 0.8rem;
}

.modal-container button.add-event-button {
  background-color: inherit;
  min-width: 0;
  font-size: 1.2rem;
  padding: 0.65rem;
}

.logout-button {
  background-color: #0f3052;
  color: white;
}

.modal-container .button-disabled {
  background-color: gray;
}

.filter-pill {
  background-color: #0f3052;
  color: white;
}

.filter-pill button {
  background: white;
  color: #0f3052;
  width: 20px;
  height: 20px;
  margin-right: -10px;
}

.color-icon {
  color: #0f3052;
}

/* React grid generic styles */
.react-resizable-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
  width: 20px;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  @apply container-border;
  background: grey;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item>.react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 10px;
  height: 10px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide>.react-resizable-handle {
  display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w,
.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n,
.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* Profile */
.profile-container h3 {
  color: #f68c1e;
}

.profile-container p {
  color: #0f3052;
}

.profile-container h1 {
  color: #0f3052;
}

/* About */
.about-container h3 {
  color: #f68c1e;
}

.about-container p {
  color: #0f3052;
}

.about-container h1 {
  color: #0f3052;
}

.about-container h2 {
  color: #f68c1e;
}

.about-container ul {
  color: #0f3052;
}

/* Account form */
.account-form h4 {
  color: #f68c1e;
}

/* Loader */
.loader-fill {
  fill: #0f3052;
}

.loader-global-container {
  @apply flex justify-center items-center min-h-screen min-w-full absolute top-0;
}

/* CalendarEvents */

.calendarEvents-container {
  background-color: #ffffff;
  border: 1px solid #0f3052;

  border-radius: 3px;
}

.calendar-input {
  background-color: transparent;
  outline: none;
  border: 1px solid #0f3052;

  @apply w-full h-full rounded-lg;
}

.calendar-input::placeholder {
  font-size: 15px;
}

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background-color: #0f3052;
  color: #ffffff;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: #0f3052;
}

.flatpickr-day {
  border-radius: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: #0f3052;
  border-color: #0f3052;
}

/* AssetDetailsInputField */
.asset-title-data {
  color: #0f3052;
}

.asset-title-data+.asset-data {
  color: black;
}

.table-head {
  @apply border-b text-left sticky h-10 top-[0px] bg-white z-10;
}

.table-head-item {
  @apply pr-2 py-3;
}

td.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.small-button {
  height: 60%;
  line-height: 24px !important;
  padding: 0 10px !important;
  box-sizing: border-box;
  font-size: 12px;
}

.filter button.small-button,
.modal-container button.small-button,
.login-container button.small-button,
.profile-container button.small-button {
  min-width: 80px;
  border: 4px solid green;
}